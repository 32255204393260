import React from 'react'
import PropTypes from 'prop-types'

import { Layout, Navbar } from '../Layout'
import { GameBoard } from '../components/Game/GameBoard'

const NewGame = ({location}) => (
	<Layout
		title="Utter"
		navbar={<Navbar position="absolute"/>}
		content={<GameBoard location={location}/>}
		footer={null}
	/>
)

NewGame.propTypes = {
	location: PropTypes.object
}

export default NewGame